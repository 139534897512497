<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col
        cols="12" sm="7" md="7" lg="7"
         class="pb-1"
      >
          <v-btn text icon large class="mr-3" @click="$router.go(-1)">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
        <span class="headline text-capitalize accent--text pt-3" v-text="action_restaurant + ' ' +$route.name"></span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="pt-0">
        <v-card flat class="mt-0">
          <v-card-text>
            <validation-observer ref="restaurant">
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-subheader>Datos principales</v-subheader>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider name="Nombre del Restaurante" vid="name" rules="required" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.name" outlined label="Nombre del Restaurante" name="Nombre del Restaurante" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider name="Nombre Fiscal" vid="fiscal_name" rules="required" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.fiscal_name" outlined label="Nombre Fiscal" name="Nombre Fiscal" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
                  <validation-provider name="Email" vid="email" rules="required|email" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.email" outlined label="Email" name="email" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
                  <validation-provider name="Telefono" vid="phone_number" rules="required|numeric" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.phone_number" outlined label="Teléfono" name="Telefono" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
                  <validation-provider name="CIF" vid="cif" rules="required" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.cif" outlined label="CIF" name="CIF" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0"></v-col>
                <v-col cols="12" class="pt-0 px-0">
                  <v-subheader class="px-0">Ubicación</v-subheader>
                  <v-divider />
                </v-col>
                <v-col cols="12" class="py-0" v-if="restaurant.id > 0">
                  <validation-provider name="Marcar en el mapa" vid="lat" rules="" v-slot="{ errors }">
                    <v-text-field dense v-model="restaurant.lat" class="d-none"/>
                    <google-map v-model="locationMap"
                      @change="setAdrres"
                      :error="errors[0]"
                      :lat="position.lat"
                      :lng="position.lng"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
                  <validation-provider name="Páis" vid="country" rules="required|alpha_spaces" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.country" outlined label="País" name="Pais" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
                  <validation-provider name="Ciudad" vid="city" rules="required|alpha_spaces" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.city" outlined label="Ciudad" name="Ciudad" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
                  <validation-provider name="Provincia" vid="province" rules="required" v-slot="{ errors }">
                    <v-text-field v-model="restaurant.province" outlined label="Provincia" name="Provincia" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                 <v-col cols="12" sm="6" md="6" lg="6" class="py-0 pr-0">
                    <validation-provider name="Código Postal" vid="city" rules="required|numeric" v-slot="{ errors }">
                      <v-text-field label="Código Postal" v-model="restaurant.cod_postal" outlined name="Codigo" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider name="Direccion" vid="address" rules="required" v-slot="{ errors }">
                     <v-textarea
                                    name="Dirección"
                                    label="Dirección"
                                    auto-grow
                                    :error-messages="errors[0]"
                                    prepend-inner-icon="mdi-playlist-edit"
                                    outlined
                                    placeholder=" "
                                    v-model="restaurant.address"
                                    :disabled="saving_restaurant"
                                  ></v-textarea>
                  </validation-provider>
                </v-col>
                <template v-if="$route.params.id === 'registrar'">
                  <v-col cols="12" class="py-0">
                    <v-subheader>Datos de Usuario</v-subheader>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <validation-provider name="Usuario" vid="username" rules="required" v-slot="{ errors }">
                      <v-text-field v-model="restaurant.username" outlined label="Usuario" name="Usuario" prepend-inner-icon="mdi-account-circle" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="saving_restaurant"/>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <validation-provider name="Contraseña" vid="password" rules="required" v-slot="{ errors }">
                      <v-text-field v-model="restaurant.password" outlined id="password" label="Contraseña" name="Contraseña" prepend-inner-icon="mdi-lock" type="password" :error-messages="errors[0]" :disabled="saving_restaurant"/></v-text-field>
                    </validation-provider>
                  </v-col>
                </template>
              </v-row>
            </validation-observer>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="info" large @click="validateRestaurant" :loading="saving_restaurant">
              <v-icon left>mdi-content-save-all-outline</v-icon> <span v-text="action_restaurant" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <base-material-snackbar
      v-model="snackbar.active"
      :type="snackbar.type"
      bottom
      right
    >
      {{ snackbar.text }}
    </base-material-snackbar>
  </v-container>
</template>

<script>
  import GoogleMap from "../../components/Map";
  export default {
    name: 'restaurant_create',
    components: { GoogleMap },
    data () {
      return {
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        search:'',
        selected_user: [],
        load_user: false,
        saving_restaurant: false,
        show: false,
        restaurant:{
          name: '',
          fiscal_name: '',
          email: '',
          address: '',
          city: '',
          province: '',
          country: '',
          phone_number: '',
          username: '',
          password: '',
          cif: '',
          rol: 'Dueño restaurante',
          restaurant_id: '',
          cod_postal: '',
          lat: 0,
          lon: 0,
        },
        action_restaurant: 'Registrar',
        id_user: '',
        locationMap: {},
      }
    },
    created(){
      if(this.$route.params.id != 'registrar'){
        this.getRestaurant();
        this.action_restaurant = "Actualizar";
        // this.restaurant.restaurant_id = this.$route.params.id;
      }
    },
    beforeRouteUpdate (to, from, next) {
      let Exp = /^([0-9])*$/;
        if(Exp.test(to.params.id) || to.params.id === 'registrar'){
          this.resetRestaurant();
          next();
        }
        else{
          next('/tc-admin/error');
        }
    },
    methods: {
      setAdrres(){
        this.restaurant.address = this.locationMap.address;
        this.restaurant.city = this.locationMap.administrative_area_level_1;
        this.restaurant.province = this.locationMap.locality;
        this.restaurant.country = this.locationMap.country;
        this.restaurant.cod_postal = this.locationMap.postal_code;
        this.restaurant.lat = this.locationMap.lat;
        this.restaurant.lon = this.locationMap.lng;
      },
      async getRestaurant(){
        // this.action_restaurant = 'Actualizar';
        const url = `api/restaurants/${this.$route.params.id}/edit`;
        try{
          const { data } = await this.$api.get(url);
          this.restaurant = {...data.data.restaurant, restaurant_id:data.data.restaurant.id };
        }
        catch(e){
          this.snackbar = {
            text:  e.response.data.message,
            type: 'error',
            active: true
          }
        }
      },
      resetRestaurant(){
        this.restaurant = {
          name: '',
          fiscal_name: '',
          email: '',
          address: '',
          city: '',
          province: '',
          country: '',
          phone_number: '',
          username: '',
          password: '',
          cif: '',
          rol: 'Dueño restaurante',
          restaurant_id: '',
          cod_postal: '',
          lat: 0,
          lon: 0,
        };
        this.action_restaurant= 'Registrar';
        this.id_user= '';
        this.$refs.restaurant.reset();
      },
      validateRestaurant(){
        this.$refs.restaurant.validate().then(result =>{
          if(result){
            this.Restaurantregister();
          }
        });
      },
      async Restaurantregister(){
        this.saving_restaurant = true;
        const url = this.action_restaurant == 'Registrar' ? 'api/users' : `api/restaurants/${this.restaurant.id}`;
        const method = this.action_restaurant == 'Registrar' ? 'post' : 'put';
        try{
          const send_restaurant = await this.$api({
            url: url,
            method: method,
            data: this.restaurant
          });
          // this.resetRestaurant();
          this.$router.push('/tc-admin/restaurantes');
          this.saving_restaurant = false;
          this.snackbar = {
            text: send_restaurant.data.message,
            type: 'success',
            active: true
          }
        }
        catch(e){
          this.saving_restaurant = false;
          if(e.response.status == "422"){
            this.$refs.restaurant.setErrors(e.response.data.data);
          }
          else{
            this.snackbar = {
              text:  e.response.data.message,
              type: 'error',
              active: true
            }
          }
        }
      },
    },
    computed:{
      position(){
        return {
          lat: parseFloat(this.restaurant.lat),
          lng: parseFloat(this.restaurant.lon),
        }
      }
    }
  }
</script>
